import React from "react";
import SEO from "../../components/seo";

const PatientForms = () => {
	return (
		<div>
			<SEO
				title="Patient Forms"
				keywords={[
					`New patient`,
					`Patient forms`,
					`Gastroenterologist`,
					`Hepatologist`,
					`Dr Frank`,
					`Dr Brennan`,
					`Dr Spiro`,
					`Dr Raftopoulos`,
					`Dr Ian Yusoff`,
					`Dr Dev Segarajasingam`,
					`Dr Sam Rao`,
					`Dr Duy Pham`,
					`Dr Claire Gordon`,
				]}
				description="Forms that need to be filled out prior to your coming in"
			/>
			Patient forms currently unavailable online. Please give us a call.
		</div>
	);
};

export default PatientForms;
